<template>
  <div class="main-content">
    <div class="main-title">管理员分组编辑</div>
    <div class="from">
      <div class="row">
        <div class="title">分组名称</div>
        <input type="text" placeholder="请输入分组名称" v-model.trim="groupInfo.name">
      </div>
      <template v-for="(items,index) in permissions">
      <div class="row" :key="index">
        <div class="title">权限-{{items.name}}</div>
        <div class="checkbox">
          <el-checkbox-group v-model="selectPermissions" @change="handleCheckedPermissionsChange">
            <el-checkbox v-for="item in items.adminPermissions" :label="item.permission" :key="item.permission" border>{{item.name}}</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      </template>
      <div class="btn-box">
        <span class="btn-confirm" @click="editGroup">确定</span>
      </div>
    </div>
  </div>
</template>

<script>
import {getPermissionsList, getGroupInfo, editGroup} from "../../../request/api";
import {Warning} from "@/plugins/warning";

export default {
  name: "sys_admingroup",
  components:{

  },
  data() {
    return {
      id:0,
      permissions: [],
      selectPermissions:[],
      groupInfo:{}
    }
  },
  computed: {

  },
  created() {
    this.id = this.$route.query.id;
    this.getPermissionsList();
    if(this.id > 0){
      this.getGroupInfo();
    }else{
      this.id = 0;
    }
  },
  mounted() {

  },
  methods: {
    handleCheckedPermissionsChange(val){
      console.log(val)
    },
    getPermissionsList(){
      const requestData = {};
      getPermissionsList(requestData).then((res)=>{
        this.permissions = res.data
      })
    },
    getGroupInfo(){
      const requestData = {"id":this.id};
      getGroupInfo(requestData).then((res)=>{
        this.groupInfo = res.data
        this.selectPermissions = res.data.permissions.split("|")
      })
    },
    editGroup(){

      let permissionsArr = [];

      this.selectPermissions.forEach(item=>{
        if(item != ""){
          permissionsArr.push(item);
          let p = item.split("_");
          permissionsArr.splice(0,0,p[0])
        }
      })

      permissionsArr = [...new Set(permissionsArr)];

      const requestData = {
        "id":this.id,
        "name":this.groupInfo.name,
        "permissions":permissionsArr
      };
      editGroup(requestData).then(()=>{
        Warning.success("操作成功");
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@/css/_var.scss";
.main-content{
  width: 100%;
  border-radius: 30px;
  background: #fff;
  box-shadow: 0 15px 30px  rgba(0, 0, 0, .1);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-bottom: 45px;
  .main-title{
    font-size: 24px;
    font-weight: 600;
    color: #141414;
    margin-left: 30px;
    margin-top: 30px;
  }
  .from{
    width: 100%;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 15px;
    border-collapse:separate;
    border-spacing:0px 30px;
    .row{
      width: 100%;
      padding-top: 15px;
      padding-left: 15px;
      padding-right: 15px;
      .title{
        height: 40px;
        line-height: 40px;
        font-size: 18px;
        font-family: PingFang HK-Regular, PingFang HK;
        font-weight: 400;
        color: #000000;
        margin-bottom: 15px;
      }
      & > input{
        height: 50px;
        min-width: 300px;
        border-radius: 25px 25px 25px 25px;
        opacity: 1;
        border: 2px solid #DDD1B4;
        padding-left: 15px;
        padding-right: 15px;
      }
      .checkbox{
        min-width: 300px;
        .el-checkbox{
          margin-left: 0px;
          margin-right: 15px;
          margin-bottom: 15px;
        }
      }
    }
    .btn-box{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 40px;
      padding-left: 15px;
      padding-right: 15px;
      .btn-confirm{
        display: block;
        width: 100%;
        line-height: 48px;
        font-size: 21px;
        font-weight: 300;
        text-align: center;
        cursor: pointer;
        color: #fff;
        border-radius: 25px;
        background-color: #ba0704;
      }
    }
  }
}
</style>